<template>
  <v-container>
    <v-card class="mx-auto d-flex flex-column charcoal">
      <BaseScreenHeader
        title="My Account"
        screenInfo="ACT-001"
        :showBack="true"
      />

      <v-card flat tile class="transparent">
        <v-card-actions class="px-0 mb-2">
          <v-spacer />
          <BaseActionButton
            plain
            icon="mdi-tune-vertical"
            :large="!isPhone"
            label="Profile & Preferences"
            class="ml-2 paper--text"
            @clickedThis="$router.push('/profile')"
          />
        </v-card-actions>
        <v-expansion-panels
          tile
          v-if="loaded"
          focusable
          hover
          class="elevation-0 "
        >
          <v-expansion-panel value="0" class="charcoalTileMenu">
            <v-expansion-panel-header
              ripple
              dark
              class="charcoalTile charcoal pl-0 pr-2"
            >
              <v-card-actions class="silver--text">
                <v-icon :large="!isPhone" class="silver--text mr-2">
                  mdi-account-edit
                </v-icon>
                <span :class="isPhone ? 'caption' : 'title'">
                  Personal Info
                </span>
                <v-spacer />
                <span :class="isPhone ? 'caption' : 'title'">
                  {{ this.accountInfo.fullname }}
                </span>
              </v-card-actions>
              <template v-slot:actions>
                <v-icon color="paper">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-2 paper">
              <PersonInfo
                v-bind:accountInfo="accountInfo"
                @updateInfo="updateInfo"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel value="0">
            <v-expansion-panel-header
              ripple
              dark
              class="charcoalTile charcoal pl-0 pr-2"
            >
              <v-card-actions class="silver--text">
                <v-icon :large="!isPhone" class="silver--text mr-2">
                  mdi-map-marker
                </v-icon>
                <span :class="isPhone ? 'caption' : 'title'">
                  Address
                </span>
                <v-spacer />
                <span :class="isPhone ? 'caption' : 'title'">
                  {{ this.shortAddress }}
                </span>
              </v-card-actions>
              <template v-slot:actions>
                <v-icon color="paper">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="paper">
              <Address
                v-bind:accountInfo="accountInfo"
                @updateInfo="updateInfo"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel value="0" v-if="xvy">
            <v-expansion-panel-header
              ripple
              dark
              class="charcoalTile charcoal pl-0 pr-2"
            >
              <v-card-actions class="silver--text">
                <v-icon :large="!isPhone" class="silver--text mr-2">
                  mdi-cash-register
                </v-icon>
                <span :class="isPhone ? 'caption' : 'title'">
                  Billing
                </span>
                <v-spacer />
                <BaseActionButton
                  icon=""
                  :large="!isPhone"
                  label="View Billing"
                  class="mr-n4 transparent silver--text px-0"
                  @clickedThis="$router.push('/billing')"
                />
              </v-card-actions>
              <template v-slot:actions>
                <v-icon color="charcoal">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
          </v-expansion-panel>
          <v-expansion-panel value="0">
            <v-expansion-panel-header
              ripple
              dark
              class="charcoalTile charcoal pl-0 pr-2"
            >
              <v-card-actions class="silver--text">
                <v-icon :large="!isPhone" class="silver--text mr-2"
                  >mdi-phone</v-icon
                >
                <span :class="isPhone ? 'caption' : 'title'">
                  Phone
                </span>
                <v-spacer />
                <span :class="isPhone ? 'caption' : 'title'">
                  {{ this.phoneNumber }}
                </span>
              </v-card-actions>
              <template v-slot:actions>
                <v-icon color="paper">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="paper">
              <Phone
                v-bind:accountInfo="accountInfo"
                @updateInfo="updateInfo"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel value="0">
            <v-expansion-panel-header
              ripple
              dark
              class="charcoalTile charcoal pl-0 pr-2"
            >
              <v-card-actions class="silver--text">
                <v-icon :large="!isPhone" class="silver--text mr-2"
                  >mdi-email</v-icon
                >
                <span :class="isPhone ? 'caption' : 'title'">
                  Email
                </span>
                <v-spacer />
                <span :class="isPhone ? 'caption' : 'title'">
                  {{ this.accountInfo.email }}
                </span>
              </v-card-actions>
              <template v-slot:actions>
                <v-icon color="paper">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="paper">
              <Email
                v-bind:accountInfo="accountInfo"
                @updateInfo="updateInfo"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              ripple
              dark
              class="charcoalTile charcoal pl-0 pr-2"
            >
              <v-card-actions class="silver--text">
                <v-icon :large="!isPhone" class="progressActive--text mr-2">
                  mdi-fingerprint
                </v-icon>
                <span :class="isPhone ? 'caption' : 'title'">
                  Change Password
                </span>
              </v-card-actions>
              <template v-slot:actions>
                <v-icon color="paper">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="paper pa-2">
              <v-card class="elevation-0 mx-auto paper">
                <v-card-text class="pa-0">
                  <v-form @submit.prevent="changePassword">
                    <v-text-field
                      v-model="oldpassword"
                      :error-messages="oldpasswordErrors"
                      :type="showPassword ? 'text' : 'password'"
                      label="Old Password"
                      ref="oldpassword"
                      prepend-icon="mdi-fingerprint"
                      :counter="250"
                      @input="$v.oldpassword.$touch()"
                      @blur="$v.oldpassword.$touch()"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    />
                    <v-text-field
                      v-model="password"
                      ref="password"
                      :error-messages="passwordErrors"
                      :type="showPassword ? 'text' : 'password'"
                      :counter="250"
                      label="Password"
                      prepend-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      @input="$v.password.$touch()"
                      @blur="$v.password.$touch()"
                    />
                    <v-text-field
                      v-model="confpassword"
                      ref="password"
                      :error-messages="confPasswordErrors"
                      :type="showPassword ? 'text' : 'password'"
                      :counter="250"
                      label="Confirm Password"
                      prepend-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      @input="$v.confpassword.$touch()"
                      @blur="$v.confpassword.$touch()"
                    />
                  </v-form>
                </v-card-text>
                <v-card-actions class="pa-0 pt-2">
                  <v-spacer />
                  <BaseActionButton
                    v-bind="$attrs"
                    class="charcoal paper--text"
                    icon="mdi-lock-reset"
                    :large="!isPhone"
                    :disabled="this.$v.$anyError"
                    @clickedThis="changePassword"
                    :label="'Change Password'"
                  />
                </v-card-actions>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              ripple
              dark
              class="charcoalTile charcoal pl-0 pr-2"
            >
              <v-card-actions class="silver--text">
                <v-icon :large="!isPhone" class="orange--text mr-2">
                  mdi-skull
                </v-icon>
                <span :class="isPhone ? 'caption' : 'title'">
                  <span class="orange--text">
                    Delete Account
                  </span>
                </span>
              </v-card-actions>
              <template v-slot:actions>
                <v-icon color="paper">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="charcoal charcoalTile">
              <v-card class="elevation-0  transparent ">
                <v-card-text class="pa-0 caption silver--text">
                  Deleting your account canot be undone.
                  <v-spacer class="mb-2" />
                  Once your account gets deleted, it can not be recovered. All
                  you information will be permanently lost.
                  <v-spacer class="mb-2" />
                  If you still want to continue, type "DELETE" in the box below
                  and then click the "DELETE ACCOUNT" button. This will also log
                  you out.
                </v-card-text>
                <v-card-actions>
                  <v-text-field
                    dark
                    v-model="confirmDelete"
                    type="text"
                    label="Confirm account deletion"
                    ref="delete"
                    prepend-inner-icon="mdi-trash-can-outline"
                    @click:append="showPassword = !showPassword"
                  />
                </v-card-actions>
                <v-card-actions class="pa-0 pt-2 mr-n2">
                  <v-spacer />
                  <BaseActionButton
                    :disabled="confirmDelete != 'DELETE'"
                    plain
                    dark
                    v-bind="$attrs"
                    color="orange"
                    class="charcoal orange--text"
                    icon="mdi-trash-can-outline"
                    large
                    @clickedThis="deleteAccount"
                    label="Delete Account"
                  />
                </v-card-actions>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-spacer />
    </v-card>
  </v-container>
</template>
<script>
import axios from 'axios'
import {
  required,
  maxLength,
  minLength,
  sameAs
} from 'vuelidate/lib/validators'
import { isPasswordValid } from '@/validators'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const PersonInfo = () =>
  import(/* webpackPrefetch: true */ '@/components/account/PersonInfo.vue')
const Address = () =>
  import(/* webpackPrefetch: true */ '@/components/account/Address.vue')
const Phone = () =>
  import(/* webpackPrefetch: true */ '@/components/account/Phone.vue')
const Email = () =>
  import(/* webpackPrefetch: true */ '@/components/account/Email.vue')
export default {
  mixins: [util],
  name: 'MyAccountPage',
  components: { PersonInfo, Address, Phone, Email },
  data() {
    return {
      showPassword: false,
      oldpassword: '',
      confirmDelete: '',
      password: '',
      confpassword: '',
      error: '',
      loaded: false,
      accountInfo: {}
    }
  },
  validations: {
    oldpassword: { required },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(250),
      isPasswordValid: isPasswordValid
    },
    confpassword: {
      sameAsPassword: sameAs('password')
    }
  },
  computed: {
    phoneNumber() {
      return this.accountInfo.international
        ? this.accountInfo.international
        : 'Phone not set'
    },
    shortAddress() {
      if (this.accountInfo.city === null) return 'Address not set.'
      let addr =
        this.accountInfo.city +
        ', ' +
        this.accountInfo.state +
        ' ' +
        this.accountInfo.zip
      return addr
    },
    oldpasswordErrors() {
      const errors = []
      if (!this.$v.oldpassword.$dirty) return errors
      !this.$v.oldpassword.required &&
        errors.push('Please enter your old password.')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('New password is required.')
      !this.$v.password.isPasswordValid &&
        errors.push(
          'Invalid password. Password must be: minimum 8 characters long, maximum 250 long and contain at least one uppercase letter, one lowercase letter and one number. '
        )
      return errors
    },
    confPasswordErrors() {
      const errors = []
      if (!this.$v.confpassword.$dirty) return errors
      !this.$v.confpassword.sameAsPassword &&
        errors.push('Confirm password should be the same as password.')
      return errors
    },
    ...appConfig
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      return axios.get(this.baseURL + '/users', {}).then(response => {
        if (response.status == 200) {
          this.accountInfo = response.data.data
          this.$store.dispatch('session/updateUser', this.accountInfo)
          this.loaded = true
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    },
    updateInfo() {
      this.loadData()
    },
    goTo(path) {
      if (this.$route.path != path) this.$router.push(path)
    },
    deleteAccount() {
      return axios.delete(this.baseURL + '/user', {}).then(response => {
        if (response.status == 200) {
          this.toast('Your account was succesfully deleted. Good bye.')
          this.appLogout()
          this.$store.dispatch('session/logout')
          this.goTo('/')
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    },
    changePassword() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      } else {
        return axios
          .put(this.baseURL + '/users/changepass', {
            oldpassword: this.oldpassword,
            password: this.password,
            confpassword: this.confpassword
          })
          .then(response => {
            if (response.status == 200) {
              this.oldpassword = ''
              this.password = ''
              this.confpassword = ''
              this.$v.$reset()
              this.toast('Your password was succesfully changed')
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
