<template>
  <v-container>
    <BaseScreenHeader title="Invoices" screenInfo="BIL-002" :showBack="true" />

    <v-card flat class="transparent d-flex flex-column" height="100%">
      <v-card-title class="progressActive--text pr-0">
        Invoices and Payments
        <v-spacer />
      </v-card-title>
      <v-card-text class="silver--text" v-if="isPhone">
        To download receipts or invoices please visit this page from a computer.
      </v-card-text>
      <template v-if="loaded">
        <v-card
          class="ml-2 charcoal mb-2 mr-2 charcoalTile"
          outlined
          v-for="invoice in invoices"
          :key="invoice.id"
        >
          <v-card-title class="silver--text pt-2 pb-0">
            Invoice For: {{ invoice.name }}
            <v-spacer />
            <span :class="getColor(invoice.payment_status)">
              {{ capitalize(invoice.payment_status) }}
            </span>
          </v-card-title>
          <v-card-text class="silver--text py-0">
            Shows on your bank statement as:
            <span class="silver--text">
              {{ invoice.statement_descriptor }}
            </span>
          </v-card-text>
          <v-card-text class="caption paper--text">
            SKU: {{ invoice.sku }}
            <v-spacer />
            Price: {{ invoice.price }} {{ invoice.currency }} /
            {{ invoice.recurrence }}
            <v-spacer />
            Due: {{ invoice.due }} {{ invoice.currency }}
            <v-spacer />
            Paid: {{ invoice.paid }} {{ invoice.currency }}
          </v-card-text>
          <v-card-actions class="pt-0 px-2 caption silver--text">
            Invoice Date: {{ prettyDate(invoice.payment_date) }}
            <v-spacer />
            <template v-if="!isPhone">
              <a
                :href="invoice.invoice_pdf"
                title="Download Invoice"
                class="mr-2"
              >
                Download Invoice
              </a>
              <a
                :href="invoice.hosted_invoice_url"
                title="View Receipt And Invoice"
              >
                View Receipt
              </a>
            </template>
          </v-card-actions>
        </v-card>
      </template>
      <template v-if="invoices.length == 0">
        <v-card-text>
          You don't have any invoices. You will get invoiced once you have an
          active subscription to BFIT Pro.
          <v-spacer />
          Click
          <a href="/upgrade" title="Upgrade to BFit Pro">"Upgrade TO PRO"</a>
          to unlock all BFit features (unlimited workout templates, statistics,
          history, measurements).
          <v-spacer />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <BaseLinkButton
            label="UPGRADE TO PRO"
            class="success--text"
            icon="mdi-arrow-up-circle"
            to="/upgrade"
          />
        </v-card-actions>
      </template>

      <v-spacer />
      <v-card-actions>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  name: 'Billing',

  data() {
    return {
      invoices: [],
      selectedSubscription: null,
      livemode: 'Yes',
      loaded: false,
      months: 1,
      switchNum: 0
    }
  },

  computed: {
    ...appConfig
  },
  beforeMount() {
    this.loadInvoices()
  },
  methods: {
    showMonthly(invoice) {
      this.selectedSubscription = invoice
      this.showMonthlyDialog = true
    },
    showYearly(invoice) {
      this.selectedSubscription = invoice
      this.showYearlyDialog = true
    },
    showCancel(invoice) {
      this.selectedSubscription = invoice
      this.showCancelDialog = true
    },
    showPause(invoice) {
      this.selectedSubscription = invoice
      this.showPauseDialog = true
    },
    showResume(invoice) {
      this.selectedSubscription = invoice
      this.showResumeDialog = true
    },
    getColor(status) {
      if (status == 'canceled') return 'error--text'
      else if (status == 'paused') return 'orange--text'
      else if (status == 'active' || status == 'paid') return ' success--text'
    },

    loadInvoices() {
      return axios
        .get(this.baseURL + '/subscription/payments', {})
        .then(response => {
          if (response.status == 200) {
            this.invoices = response.data.data
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
