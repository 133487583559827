<template>
  <v-container>
    <BaseScreenHeader title="Billing" screenInfo="BIL-001" :showBack="true" />
    <v-card flat class="d-flex flex-column transparent">
      <v-card-actions class="pa-0">
        <v-spacer />
        <BaseLinkButton
          plain
          label="My Invoices"
          color="paper"
          icon=""
          to="/invoices"
        />
      </v-card-actions>
      <v-card-title class="silver--text  pb-0">
        <v-divider />
        <span class="mx-2">Subscriptions</span>
        <v-divider />
      </v-card-title>
      <template v-if="loaded">
        <v-card
          rounded
          class="transparent charcoalTile mb-2"
          v-for="subscription in subscriptions"
          :key="subscription.id"
        >
          <v-card-title class="paper--text">
            {{ subscription.name }}
            <v-spacer />
            <span
              v-if="subscription.active_strict == 'Yes'"
              :class="getColor(subscription.status)"
            >
              {{ capitalize(subscription.status) }}
            </span>
            <span class="silver--text" v-else> Expired </span>
          </v-card-title>
          <v-card-text class="silver--text">
            {{ subscription.description }}
            <v-spacer />
            Shows on your bank statement as:
            <span class="progressActive--text">
              {{ subscription.statement_descriptor }}
            </span>
          </v-card-text>
          <v-card-text class="silver--text">
            SKU: {{ subscription.sku }}
            <v-spacer />
            Price: {{ subscription.price }} per {{ subscription.recurrence }}
            <v-spacer />
            <span class="progressActive--text mt-4">
              {{ subscription.variation_description }}
            </span>
          </v-card-text>
          <v-card-text class="pt-0 caption silver--text">
            Initial Start Date: {{ prettyDateTime(subscription.startdate) }}
            <v-spacer />
            Last Start Date:
            {{ prettyDateTime(subscription.period_startdate) }}

            <template v-if="subscription.resumes_at != null">
              <v-spacer />
              <span class="orange--text">
                Resumes On: {{ prettyDateTime(subscription.resumes_at) }}
              </span>
            </template>
            <template
              v-if="
                subscription.canceldate != null &&
                  subscription.status == 'canceled'
              "
            >
              <v-spacer />
              Cancel On: {{ prettyDateTime(subscription.canceldate) }}
            </template>
            <v-spacer />
            <span
              :class="
                subscription.active_strict == 'Yes'
                  ? 'progressActive--text'
                  : 'silver--text'
              "
            >
              {{
                subscription.status == 'active' || subscription.status == 'paid'
                  ? 'Renews On: '
                  : 'Active Until'
              }}
              {{ prettyDate(subscription.enddate) }}
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <BaseLinkButton
              v-if="subscription.status == 'canceled'"
              label="Re-Subscribe"
              class="info--text"
              icon="mdi-redo-variant"
              to="/upgrade"
            />
          </v-card-actions>
          <v-card-actions v-if="subscription.type == 'Trial'">
            <v-spacer />
            <BaseLinkButton
              label="UPGRADE TO PRO"
              class="success--text"
              icon="mdi-arrow-up-circle"
              to="/upgrade"
            />
          </v-card-actions>
          <template
            v-if="
              subscription.active_strict == 'Yes' &&
                (subscription.status == 'active' ||
                  subscription.status == 'paid')
            "
          >
            <template v-if="subscription.external_type == 'Stripe'">
              <v-card-actions class="py-0">
                <v-checkbox
                  dark
                  v-model="showMore"
                  on-icon="mdi-chevron-up-circle"
                  off-icon="mdi-chevron-down-circle"
                  label="More?"
                />

                <v-spacer />
                <BaseActionButton
                  v-if="subscription.recurrence == 'month'"
                  icon="mdi-arrow-up-box"
                  label="Switch to Yearly"
                  color="progressActive"
                  text
                  @clickedThis="showYearly(subscription)"
                />
                <BaseActionButton
                  v-if="subscription.recurrence == 'year'"
                  icon="mdi-arrow-up-box"
                  label="Switch to Monthly"
                  color="progressActive"
                  text
                  @clickedThis="showMonthly(subscription)"
                />
              </v-card-actions>
              <template v-if="showMore">
                <v-card-actions>
                  <v-icon class="silver--text" small>mdi-close</v-icon>
                  <a
                    class="silver--text caption"
                    :title="'Cancel Subscription To ' + subscription.name"
                    @click="showCancel(subscription)"
                    >Cancel Subcription
                  </a>
                  <v-spacer />
                  <v-icon class="silver--text" small>mdi-pause</v-icon>
                  <a
                    class="silver--text caption"
                    :title="'Pause Subscription To ' + subscription.name"
                    @click="showPause(subscription)"
                    >Pause Subcription
                  </a>
                </v-card-actions>
              </template>
              <v-card-actions v-if="subscription.status == 'paused'">
                <v-spacer />
                <v-icon class="success--text" small>mdi-redo-variant</v-icon>
                <a
                  class="success--text caption"
                  :title="'Resume Subscription To ' + subscription.name"
                  @click="showResume(subscription)"
                  >Resume Subcription
                </a>
              </v-card-actions>
            </template>
            <template v-else>
              <v-card-text class="caption silver--text px-2 text-center">
                You can manage this subscription using the
                {{
                  subscription.external_type == 1
                    ? 'Apple Store'
                    : 'Google Play'
                }}.
              </v-card-text>
            </template>
          </template>
        </v-card>
      </template>
      <template v-if="subscriptions.length == 0">
        <v-card-text class="silver--text">
          You don't have any subscriptions.
          <v-spacer />
          Click
          <a href="/upgrade" title="Upgrade to BFit Pro">"Upgrade TO PRO"</a>
          to unlock all BFit features (unlimited workout templates, statistics,
          history, measurements).
          <v-spacer />
          You can cancel your subscription at any time.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <BaseLinkButton
            dark
            label="UPGRADE TO PRO"
            color="success"
            icon="mdi-arrow-up-circle"
            to="/upgrade"
          />
        </v-card-actions>
      </template>
      <v-card-text
        class="silver--text"
        v-if="subscription.package != 'FREE' && portalUrl.url"
      >
        To change your subscription to BFit Pro, view invoices and billing
        history, or update your credit card information, please visit the
        <a
          title="BFitr billing portal"
          class="progressActive--text"
          :href="portalUrl.url"
          >billing portal.
        </a>
        <v-spacer />
        Pausing your subscription will stop future renewals until your
        subscription is resumed or the pause period ends.
        <v-spacer />
        Your subscription will still be active until the end of the period it
        was paid for.
      </v-card-text>
      <v-spacer />
      <v-card-actions>
        <v-spacer />
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="showPauseDialog"
      persistent
      width="600"
      :fullscreen="isPhone"
    >
      <v-card
        v-if="selectedSubscription != null"
        align="stretch"
        elevation="20"
        class="noscroll-x d-flex flex-column"
      >
        <v-sheet color="primary" class="silver--text" height="60">
          <v-card-title class="title"> Pause Subscription? </v-card-title>
        </v-sheet>
        <v-card-title
          color=""
          :class="textSizeXSmall + ' progressActive--text'"
        >
          {{ selectedSubscription.name }}
        </v-card-title>
        <v-card-subtitle class="body-1 pt-4 mb-5">
          Are you sure you want to pause your subscription to
          {{ selectedSubscription.name }}?
        </v-card-subtitle>
        <v-card-text class="py-0">
          <v-slider
            v-model="months"
            min="1"
            color="progressActive"
            step="1"
            max="3"
            :thumb-size="35"
            thumb-label="always"
            ticks="always"
            tick-size="1"
            label="Pause For"
          >
            <template v-slot:append>
              <span class="caption progressActive--text"> months</span>
            </template>
          </v-slider>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-close"
            color="info"
            label="Cancel"
            :large="!isPhone"
            plain
            @clickedThis="cancelPause"
          />
          <v-spacer />
          <BaseActionButton
            icon="mdi-pause"
            label="Yes, Pause"
            :large="!isPhone"
            plain
            @clickedThis="pauseSubscription"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showResumeDialog"
      persistent
      width="600"
      :fullscreen="isPhone"
    >
      <v-card
        v-if="selectedSubscription != null"
        align="stretch"
        elevation="20"
        class="noscroll-x d-flex flex-column"
      >
        <v-sheet color="primary" class="silver--text" height="60">
          <v-card-title class="title"> Resume Subscription? </v-card-title>
        </v-sheet>
        <v-card-title
          color=""
          :class="textSizeXSmall + ' progressActive--text'"
        >
          {{ selectedSubscription.name }}
        </v-card-title>
        <v-card-subtitle class="body-1 pt-4 mb-5">
          Are you sure you want to resume your subscription to
          {{ selectedSubscription.name }}?
        </v-card-subtitle>
        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-close"
            color="info"
            label="Cancel"
            :large="!isPhone"
            plain
            @clickedThis="cancelResume"
          />
          <v-spacer />
          <BaseActionButton
            icon="mdi-redo-variant"
            label="Yes, Resume"
            :large="!isPhone"
            plain
            @clickedThis="resumeSubscription"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showCancelDialog"
      persistent
      width="600"
      :fullscreen="isPhone"
    >
      <v-card
        v-if="selectedSubscription != null"
        align="stretch"
        elevation="20"
        class="noscroll-x d-flex flex-column"
      >
        <v-sheet color="primary" class="silver--text" height="60">
          <v-card-title class="title"> Cancel Subscription? </v-card-title>
        </v-sheet>
        <v-card-title
          color=""
          :class="textSizeXSmall + ' progressActive--text'"
        >
          {{ selectedSubscription.name }}
        </v-card-title>
        <v-card-subtitle class="body-1 pt-4 mb-5">
          Are you sure you want to cancel your subscription to
          {{ selectedSubscription.name }}? Doing so will limit your access to
          workout templates, statistics, analytics, measurements and programs.
        </v-card-subtitle>
        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-arrow-left"
            color="info"
            label="Don't Cancel"
            :large="!isPhone"
            plain
            @clickedThis="cancelCancel"
          />
          <v-spacer />
          <BaseActionButton
            icon="mdi-close"
            label="Yes, Cancel"
            :large="!isPhone"
            plain
            @clickedThis="cancelSubscription"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showYearlyDialog"
      persistent
      width="600"
      :fullscreen="isPhone"
    >
      <v-card
        v-if="selectedSubscription != null"
        align="stretch"
        elevation="20"
        class="noscroll-x d-flex flex-column"
      >
        <v-sheet color="primary" class="silver--text" height="60">
          <v-card-title class="title">
            Upgrade To Yearly Subscription?
          </v-card-title>
        </v-sheet>
        <v-card-title
          color=""
          :class="textSizeXSmall + ' progressActive--text'"
        >
          {{ selectedSubscription.name }} Yearly
        </v-card-title>
        <v-card-subtitle class="body-1 pt-4 mb-5">
          This will upgrade your subscription to BFit PRO yearly. You will save
          approx. 15% in doing so.
        </v-card-subtitle>
        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-close"
            color="info"
            label="Cancel"
            :large="!isPhone"
            plain
            @clickedThis="cancelYearly"
          />
          <v-spacer />
          <BaseActionButton
            icon="mdi-arrow-up-box"
            label="Yes, Upgrade"
            :large="!isPhone"
            plain
            color="success"
            @clickedThis="upgradeSubscription"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showMonthlyDialog"
      persistent
      width="600"
      :fullscreen="isPhone"
    >
      <v-card
        v-if="selectedSubscription != null"
        align="stretch"
        elevation="20"
        class="noscroll-x d-flex flex-column"
      >
        <v-sheet color="primary" class="silver--text" height="60">
          <v-card-title class="title">
            Switch To Monthy Subscription?
          </v-card-title>
        </v-sheet>
        <v-card-title
          color=""
          :class="textSizeXSmall + ' progressActive--text'"
        >
          {{ selectedSubscription.name }} Monthly
        </v-card-title>
        <v-card-subtitle class="body-1 pt-4 mb-5">
          This will change your subscription to BFit PRO monthly. You will lose
          about 15% in savings by switching from yearly to monthly.
        </v-card-subtitle>
        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-close"
            color="info"
            label="Cancel"
            :large="!isPhone"
            plain
            @clickedThis="cancelMonthly"
          />
          <v-spacer />
          <BaseActionButton
            icon="mdi-arrow-up-box"
            label="Yes, Switch"
            :large="!isPhone"
            plain
            color="success"
            @clickedThis="switchSubscription"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  name: 'Billing',

  data() {
    return {
      portalUrl: '',
      subscriptions: [],
      showPauseDialog: false,
      showResumeDialog: false,
      showCancelDialog: false,
      showYearlyDialog: false,
      showMonthlyDialog: false,
      selectedSubscription: null,
      livemode: 'Yes',
      loaded: false,
      showMore: false,
      months: 1,
      switchNum: 0
    }
  },

  computed: {
    ...appConfig
  },
  beforeMount() {
    this.loadSubscriptions()
  },
  methods: {
    cancelCancel() {
      this.showCancelDialog = false
      this.selectedSubscription = null
    },
    cancelResume() {
      this.showResumeDialog = false
      this.selectedSubscription = null
    },
    cancelPause() {
      this.showPauseDialog = false
      this.selectedSubscription = null
    },
    cancelYearly() {
      this.showYearlyDialog = false
      this.selectedSubscription = null
    },
    cancelMonthly() {
      this.showMonthlyDialog = false
      this.selectedSubscription = null
    },
    showMonthly(subscription) {
      this.selectedSubscription = subscription
      this.showMonthlyDialog = true
    },
    showYearly(subscription) {
      this.selectedSubscription = subscription
      this.showYearlyDialog = true
    },
    showCancel(subscription) {
      this.selectedSubscription = subscription
      this.showCancelDialog = true
    },
    showPause(subscription) {
      this.selectedSubscription = subscription
      this.showPauseDialog = true
    },
    showResume(subscription) {
      this.selectedSubscription = subscription
      this.showResumeDialog = true
    },
    getColor(status) {
      if (status == 'canceled') return 'error--text'
      else if (status == 'paused') return 'orange--text'
      else if (status == 'active' || status == 'paid')
        return ' progressActive--text'
    },
    cancelSubscription() {
      return axios
        .put(this.baseURL + '/subscription/cancel', {
          subscriptionid: this.selectedSubscription.subscriptionid,
          livemode: this.livemode
        })
        .then(response => {
          if (response.status == 200) {
            this.selectedSubscription = null
            this.showCancelDialog = false
            this.loadSubscriptions()
            this.$forceUpdate()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    pauseSubscription() {
      return axios
        .put(this.baseURL + '/subscription/pause', {
          subscriptionid: this.selectedSubscription.subscriptionid,
          livemode: this.livemode,
          months: this.months
        })
        .then(response => {
          if (response.status == 200) {
            this.selectedSubscription = null
            this.showPauseDialog = false
            this.loadSubscriptions()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    upgradeSubscription() {
      return axios
        .put(this.baseURL + '/subscription/yearly', {
          subscriptionid: this.selectedSubscription.subscriptionid,
          livemode: this.livemode
        })
        .then(response => {
          if (response.status == 200) {
            this.selectedSubscription = null
            this.showYearlyDialog = false
            this.loadSubscriptions()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    switchSubscription() {
      return axios
        .put(this.baseURL + '/subscription/monthly', {
          subscriptionid: this.selectedSubscription.subscriptionid,
          livemode: this.livemode
        })
        .then(response => {
          if (response.status == 200) {
            this.selectedSubscription = null
            this.showMonthlyDialog = false
            this.loadSubscriptions()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    resumeSubscription() {
      return axios
        .put(this.baseURL + '/subscription/resume', {
          subscriptionid: this.selectedSubscription.subscriptionid,
          livemode: this.livemode
        })
        .then(response => {
          if (response.status == 200) {
            this.selectedSubscription = null
            this.showResumeDialog = false
            this.loadSubscriptions()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadPortalUrl() {
      let mode = this.livemode == 'Yes' ? 'live' : 'test'
      return axios.get(this.baseURL + '/portal/' + mode, {}).then(response => {
        if (response.status == 200) {
          this.portalUrl = response.data.data
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    },
    loadSubscriptions() {
      return axios
        .get(this.baseURL + '/subscription/history', {})
        .then(response => {
          if (response.status == 200) {
            this.subscriptions = response.data.data
            if (this.subscriptions.length > 0) this.loadPortalUrl()
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
